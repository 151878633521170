import { formatMileage } from '../../../utils/formatter'

export default {
  data() {
    return {
      highlightedFeaturesByVehicleClass: {
        Car: [
          'exterior',
          'interior',
          'fuel',
          'power',
          'gearbox',
          'wheelFormula',
          'firstRegistration',
          'mileage'
        ],
        Motorbike: [
          'exterior',
          'interior',
          'fuel',
          'power',
          'gearbox',
          'wheelFormula',
          'firstRegistration',
          'mileage'
        ]
      }
    }
  },
  methods: {
    getHighlightedFeatures(rawVehicleData) {
      let highlightedFeatures = []

      const capitalizeFirstLetter = string =>
        string.charAt(0).toUpperCase() + string.slice(1)

      // eslint-disable-next-line no-unused-vars
      const getExterior = rawVehicleData => {
        const { exteriorColor, manufacturerColorName } = rawVehicleData

        return {
          id: 'exterior',
          label: manufacturerColorName,
          icon: exteriorColor === 'WHITE' ? 'circle' : 'circle-filled',
          iconColor: exteriorColor === 'WHITE' ? 'BLACK' : exteriorColor
        }
      }

      // eslint-disable-next-line no-unused-vars
      const getFirstRegistration = rawVehicleData => {
        const { firstRegistration } = rawVehicleData
        let featureObject = null

        if (firstRegistration !== undefined) {
          const translation = this.$t('vehicle.firstRegistrationTEXT', {
            year: firstRegistration.substring(0, 4),
            month: firstRegistration.substring(4, 6)
          })
          featureObject = {
            id: 'firstRegistration',
            label: translation,
            icon: 'calendar-today'
          }
        }

        return featureObject
      }

      // eslint-disable-next-line no-unused-vars
      const getFuel = rawVehicleData => {
        const { fuel } = rawVehicleData
        let icon = 'fuel-petrol'

        if (['HYBRID', 'HYBRID_DIESEL'].includes(fuel)) {
          icon = 'fuel-hybrid'
        } else if (fuel === 'ELECTRICITY') {
          icon = 'fuel-electric'
        }

        return {
          id: 'fuel',
          label: this.$t('vehicle.fuel' + fuel),
          icon
        }
      }

      // eslint-disable-next-line no-unused-vars
      const getGearbox = (rawVehicleData, $t) => {
        const { gearbox } = rawVehicleData
        const translation = this.$t('vehicle.gearbox' + gearbox)

        return {
          id: 'gearbox',
          label: translation,
          icon: 'gear'
        }
      }

      // eslint-disable-next-line no-unused-vars
      const getInterior = rawVehicleData => {
        let featureObject = null
        const { interiorColor, interiorType } = rawVehicleData

        const useFilledIcon =
          interiorColor &&
          !['OTHER_INTERIOR_COLOR', 'WHITE'].includes(interiorColor)
        let icon = 'circle'
        let labelSegments = []

        if (
          ['LEATHER', 'PARTIAL_LEATHER', 'ALCANTARA'].includes(interiorType)
        ) {
          icon = 'leather'
        } else if (['FABRIC', 'VELOUR'].includes(interiorType)) {
          icon = 'cloth'
        }

        if (interiorType !== 'OTHER_INTERIOR_TYPE') {
          labelSegments.push(this.$t('vehicle.interiorType' + interiorType))
        }

        if (interiorColor && interiorColor !== 'OTHER_INTERIOR_COLOR') {
          labelSegments.push(this.$t('vehicle.interiorColor' + interiorColor))
        }

        if (labelSegments.length) {
          featureObject = {
            id: 'interior',
            label: labelSegments.join(' '),
            icon: icon + (useFilledIcon ? '-filled' : ''),
            iconColor: useFilledIcon && interiorColor ? interiorColor : 'BLACK'
          }
        }

        return featureObject
      }

      // eslint-disable-next-line no-unused-vars
      const getMileage = rawVehicleData => {
        let featureObject = null
        const { mileage, condition } = rawVehicleData
        if (condition === 'USED') {
          const translation = this.$t('vehicle.mileageTEXT', {
            mileage: formatMileage(mileage)
          })
          featureObject = {
            id: 'mileage',
            label: translation,
            icon: 'street'
          }
        }

        return featureObject
      }

      // eslint-disable-next-line no-unused-vars
      const getPower = rawVehicleData => {
        const { power } = rawVehicleData
        const translation = this.$t('vehicle.powerTEXT', {
          kw: parseInt(power),
          ps: Math.round(parseInt(power) * 1.35962)
        })
        return {
          id: 'power',
          label: translation,
          icon: 'engine'
        }
      }

      // eslint-disable-next-line no-unused-vars
      const getWheelFormula = rawVehicleData => {
        let featureObject = null

        const { amWheelFormula, wheelFormula } = rawVehicleData
        const wheelFormularCode = amWheelFormula
          ? amWheelFormula
          : wheelFormula === 'WHEEL_DRIVE_4x4'
          ? 'AWD'
          : null

        if (wheelFormularCode !== null) {
          featureObject = {
            id: 'wheelFormula',
            label: this.$t(
              'vehicle.wheelFormula' + wheelFormularCode + 'Shortcut'
            ),
            icon: 'drive-' + wheelFormularCode.toLowerCase()
          }
        }

        return featureObject
      }

      const actions = {
        getExterior,
        getInterior,
        getFirstRegistration,
        getFuel,
        getGearbox,
        getMileage,
        getPower,
        getWheelFormula
      }

      if (rawVehicleData) {
        const orderedFeatureList = this.highlightedFeaturesByVehicleClass[
          rawVehicleData.vehicleClass
        ]

        if (Array.isArray(orderedFeatureList)) {
          orderedFeatureList.forEach(feature => {
            const action = 'get' + capitalizeFirstLetter(feature)

            if (actions[action]) {
              const value = actions[action](rawVehicleData)
              if (value !== null) {
                highlightedFeatures.push(value)
              }
            }
          })
        }
      }

      return highlightedFeatures
    }
  }
}
